<template>
  <div class="my-10">
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md5>
            <v-card class="elevation-12">
              <v-alert dense outlined :type="typeAlert" v-if="errorMessage != ''">
                {{ errorMessage }}
              </v-alert>
              <v-toolbar color="blue lighten-5">
                <v-toolbar-title>Login for students</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <div id="qrcodescanner" class="px-auto mx-auto"></div>
                <div id="message"></div>
              </v-card-text>
              <v-row justify="center">
                <v-col md="auto">
                  <v-btn depressed color="primary" href="/login">Back to Login</v-btn>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>
  </div>
</template>

<script>
import { auth, functions } from '../plugins/firebase'

export default {
  name: 'Login',
  data: () => ({
    account: {
      email: '',
      password: ''
    },
    errorMessage: '',
    typeAlert: '',
    cargando : false,
  }),
  created () {
    let qrcode = document.createElement('script')
    qrcode.setAttribute('src', '/llqrcode.js')
    document.body.appendChild(qrcode)
    let qr = document.createElement('script')
    qr.setAttribute('src', '/qrcodes.js')
    document.body.appendChild(qr)
  },
  methods: {
    onSubmit () {
      console.log(this.account)
      this.$store.dispatch('login', this.account).then(() => {
        console.log(this.$store.state.user)
        location.reload();
        
      }).catch(error => {
          this.cargando = false
        console.log(error);
        if (Object.prototype.hasOwnProperty.call(error, 'message')) {
          this.MessageAlert(error.message, 'error');
        }else
          this.MessageAlert(error, 'error');
      });
    },
    MessageAlert ( message, type) {
      this.typeAlert = type;
      this.errorMessage = message;
      setTimeout(() => this.errorMessage = '', 4000);
    },
    validateEmail(emailField){
      var validEmail =  /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/;
      console.log(emailField);
      // Using test we can check if the text match the pattern
      if( validEmail.test(emailField) ){
        return true;
      }else{
        return false;
      }
    },
    // GetUser(){
    //   const constraints = {
       
    //     video: { width: 1280, height: 720 },
    //   };

    //   navigator.mediaDevices
    //     .getUserMedia(constraints)
    //     .then((mediaStream) => {
    //       // const video = document.querySelector("video");
    //     })
    //     .catch((err) => {
    //       // always check for errors at the end.
    //       console.error(`${err.name}: ${err.message}`);
    //     });
    // },
  },
  mounted () {
    let me = this;
    me.cargando = false
    setTimeout(() => {
        QRCodeScanner({
            element: document.getElementById('qrcodescanner'),
            width: 400,
            height: 300,
            onScanSuccess: function(result) {
                console.log(result)
                // console.log('Scan Success', result);
                // document.getElementById('message').textContent = result;
                if (me.cargando == false) {
                  let datos = result.split(" ");
                  console.log(datos)
                  // if (me.validateEmail(datos[0])) {
                    me.account.email =datos[0];
                    me.account.password =datos[1];
                    me.onSubmit()
                  // }else{

                  // }
                  
                  me.cargando = true;
                }
                
            },
            onScanError: function(error) {
            // console.log('Scan Error', error);
            // document.getElementById('message').textContent = 'No QR Code found';
            }
        });
    }, 2000);
  }
}
</script>
<style>
  .c-pointer{
    cursor: pointer;
  }
  .loginClever{
    text-align: center;
    margin: 15px;
    font-size: 1.1em;
  }
  .loginClever a{
    text-decoration: none;
  }
  #qrcodescanner .error {
    background-color: #84e9ca !important;
    font-size: 25px;
    text-align: center;
  }
  video {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
.switchB{
  width: 50px;
  height: 50px;
  background-color: rgb(172, 172, 172);
  margin: 25% 0px 0 -25%;
  background-image: url('/camera-flip-outline.png');
  background-size: cover;
  border-radius: 10px;
}

/* .switchB::before{
  font-family: "Material Icons";
  content: "\e5cc";
} */
</style>